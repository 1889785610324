.selected {
  
}

.growShrink {
  /*Tell this element which properties to animate if something changes
  We are saying all properties will animate when changed seconds*/
  transition: all 0.1s;
}

.growShrink[data-state="on"] {
  transform: scale(1.05);
}

 .button {
  padding: 8px 8px;
  gap: 4px;
  border: none;
  border-radius: 4px;
  background: gray;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
  cursor: pointer;
  outline-offset: 4px;
}

.item:hover {
  cursor: pointer;
}


